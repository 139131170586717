import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
       <h1 style={{
      justifyContent:'center',
      color:'#0B666A'
     }}>G2 Mart</h1>
     <h2 style={{
      justifyContent:'center',
     }}>⚠️ Comming Soon...</h2>
    </div>
  );
}

export default App;
